"use client";
import { usePathname } from "next/navigation";
import { useUser } from "@auth0/nextjs-auth0/client";
import classnames from "classnames";
import Link from "next/link";
import Image from "next/image";
import styles from "./navigation.module.css";

export default function Navigation() {
  const { user, isLoading } = useUser();
  const pathname = usePathname() || "";

  return (
    <>
    <div className={styles.navigation}>
      <menu className={styles.menu}>
        <Link href="/" className={pathname == "/" ? styles.active : ""}>
          Product
        </Link>
        <Link href="/blog" className={pathname.indexOf("/blog") > -1 ? styles.active : ""}>
          Blog
        </Link>
        <Link href="/chat" className={pathname == "/chat" ? styles.active : ""}>
          Ask Virna
        </Link>
      </menu>
      </div>
      <div className={styles.navigation}>
        {isLoading && (
          <svg className={styles.avatar} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" width="40" height="40">
          <circle cx="50" cy="50" r="50" fill="#F2865E" />
        </svg>
        )}
        {!isLoading && user && (
          <>
            {(user.picture && (
              <Link className={classnames(styles.avatar, pathname == "/profile" ? styles.activeAvatar : null)} href="/profile" title="Profile">
                <Image src={user.picture} alt="user picture" width={40} height={40} />
              </Link>
            )) || (
              <Link className={styles.profile} href="/profile">
                Profile
              </Link>
            )}
          </>
        )}
        {!isLoading && !user && (
          <Link className={styles.logout} href="/profile">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="40" height="40"><title>Log in</title><path d="M50,5a45,45,0,1,0,0,90,44.493,44.493,0,0,0,28.21-9.94238A44.99844,44.99844,0,0,0,50,5Zm-.001,15.75854A14.62067,14.62067,0,1,1,35.37836,35.37921,14.62067,14.62067,0,0,1,49.999,20.75854ZM78.834,76.25781a39.24659,39.24659,0,0,1-4.38671,4.126,38.95972,38.95972,0,0,1-53.28125-4.126c-.64466-.70611-1.2522-1.43866-1.8393-2.18463,2.3938-9.915,15.20856-17.49328,30.67334-17.49328,15.45477,0,28.27594,7.5772,30.67261,17.49127C80.08582,74.81775,79.47839,75.5509,78.834,76.25781Z" fill="#F2865E" /></svg>
          </Link>
        )}
    </div>
    </>
  );
}
