import(/* webpackMode: "eager" */ "/vercel/path0/components/footer/footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/header.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navigation/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-raleway\"}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-source-sans\"}],\"variableName\":\"source_sans_3\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/css/normalize.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/css/main.css");
